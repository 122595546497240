import React from "react";

import Layout from "../layouts";
import SEO from "../components/seo";
import Hero from "../sections/hero";
import Works from "../sections/works";
import Tagline from "../sections/tagline";

import keywords from "../data/keywords.json";

function IndexPage() {
  return (
    <Layout showWaitlist={false}>
      <SEO keywords={keywords} />
      <Hero />
      <Tagline />
      <div className="w-full inline-flex items-center justify-center -mt-20">
        <svg
          width="24"
          height="100"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.9393 72.0607C11.5251 72.6464 12.4749 72.6464 13.0607 72.0607L22.6066 62.5147C23.1924 61.9289 23.1924 60.9792 22.6066 60.3934C22.0208 59.8076 21.0711 59.8076 20.4853 60.3934L12 68.8787L3.51472 60.3934C2.92893 59.8076 1.97918 59.8076 1.3934 60.3934C0.807612 60.9792 0.807612 61.9289 1.3934 62.5147L10.9393 72.0607ZM10.5 0L10.5 71H13.5L13.5 0L10.5 0Z"
            fill="black"
          />
        </svg>
      </div>
      <Works />
    </Layout>
  );
}

export default IndexPage;
