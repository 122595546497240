import React from "react";

import { Link } from "gatsby";

function Hero() {
  return (
    <section className="bg-mph-light-gray w-full flex justify-center py-8">
      <div className="max-w-7xl flex flex-col sm:flex-col flex-wrap w-full py-24 px-8 justify-center items-center">
        <div className="w-full max-w-screen-xl flex flex-col items-center justify-start p-4 space-y-6">
          <h1 className="text-center font-bold text-5xl px-4 pb-1 sm:text-8xl text-black tracking-wide leading-loose">
            All-In-One Toolkit for <br /> Better Product Management
          </h1>
          <h2 className="text-base text-center sm:text-xl text-gray-400 tracking-wider leading-loose mt-4 mb-4">
            Capture, analyse and organize feedback in one place. <br />
            Prioritize Ideas and turn them into actionable product decisions.
          </h2>
          <div className="w-full md:w-1/2 py-4 flex flex-col justify-center items-center">
            <Link
              to="https://app.myproducthub.com?action=Register"
              target="_blank"
              className="p-2 text-lg sm:p-6 rounded-lg bg-mph-ocean-blue text-white sm:text-2xl cursor-pointer transform transition duration-500 hover:scale-110"
            >
              Get Started For Free
            </Link>

            <p className="text-sm text-gray-500 mt-4">
              *14 Day Free Trial. No Card Required.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Hero;
