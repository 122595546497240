import React from "react";

import Spreadsheet from "../images/svg/spreadsheets_2.svg";
import IdeaFlow from "../images/svg/ideas_flow_2.svg";

function Tagline() {
  return (
    <>
      <section className="body-font">
        <div className="container px-5 py-24 mx-auto">
          <div className="flex flex-col text-center w-full space-y-5">
            <p className="text-3xl sm:text-5xl text-black font-bold">
              Are you struggling to
            </p>
            <p className="text-3xl sm:text-5xl text-black font-bold">
              manage your product?
            </p>
            <div className="w-full flex flex-col sm:flex-row sm:space-x-5 py-5 items-baseline justify-between">
              <div className="flex flex-col p-4 w-full sm:w-1/2">
                <img
                  alt="feature"
                  className="object-cover object-center w-full p-4"
                  src={Spreadsheet}
                />
                <p className="text-2xl text-black font-bold">
                  Still managing customer feedback
                </p>
                <p className="text-2xl text-black font-bold">
                  on messy spreadsheets and emails?
                </p>
              </div>
              <div className="flex flex-col p-4 w-full sm:w-1/2">
                <img
                  alt="feature"
                  className="object-cover object-center w-full h-2/3 p-2"
                  src={IdeaFlow}
                />
                <p className="text-2xl text-black font-bold">
                  Struggling with Idea generation
                </p>
                <p className="text-2xl text-black font-bold">
                  and management with your team?
                </p>
              </div>
            </div>
            <div className="w-full pt-10 sm:pt-24 flex flex-col space-y-10">
              <p className="text-3xl sm:text-5xl text-black font-bold">
                Do any of these sound like you?
              </p>
              <p className="text-3xl sm:text-5xl text-mph-ocean-blue font-bold">
                We have got you covered.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Tagline;
