import React from "react";
import { MdFeedback } from "react-icons/md";
import { FcIdea } from "react-icons/fc";
import { BiColumns } from "react-icons/bi";
import { FaHeart } from "react-icons/fa";
import { Link } from "gatsby";

function Works() {
  return (
    <section className="w-full">
      <div className="flex flex-col text-center w-full sm:mb-20 sm:mt-10">
        <span className="font-bold text-2xl sm:text-5xl text-black tracking-wide leading-loose text-center py-2">
          Everything you need to <br />
          manage your product
        </span>
      </div>
      <div className="w-full flex flex-col items-center justify-center">
        {/* Feedback */}
        <Link to="/product/feedback" className="w-2/3">
          <div className="bg-mph-light-gray mt-10 p-8 rounded-xl w-full flex flex-row items-center cursor-pointer transform transition duration-500 hover:scale-105">
            <div className="container mx-auto">
              <div className="flex items-center lg:w-4/5 mx-auto border-b pb-10 mb-10 border-gray-600 sm:flex-row flex-col">
                <div className="sm:w-40 sm:h-40 h-20 w-20 sm:mr-10 inline-flex items-center justify-center text-mph-orange flex-shrink-0">
                  <MdFeedback className="w-full h-full" />
                </div>
                <div className="flex-grow sm:text-left text-center mt-6 sm:mt-0 space-y-3">
                  <h2 className="text-black font-bold text-3xl title-font mb-2">
                    Collect and Manage Feedback
                  </h2>
                  <p className="leading-relaxed text-base text-gray-600">
                    Collect feedback via your customer success team or integrate
                    our API into your product.
                  </p>
                  <p className="leading-relaxed text-base text-gray-600">
                    Assign a priority and manage feedback effortlessly
                  </p>
                  <p className="leading-relaxed text-base text-gray-600">
                    Assign a feedback to an existing Idea for better tracking
                  </p>
                  <p className="leading-relaxed text-base text-gray-600">
                    Ditch back and forth on excel and slack. Communicate with
                    your team right from the app.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Link>

        <div className="w-2/3 inline-flex justify-start -mt-12 -mb-16 z-30">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="200"
            height="200"
            viewBox="0 0 40 40"
            fill="none"
          >
            <path
              d="M12.4842 29.3233C12.7204 29.1802 12.7959 28.8728 12.6528 28.6366L10.3214 24.7876C10.1784 24.5514 9.87091 24.4759 9.63471 24.6189C9.39852 24.762 9.32302 25.0695 9.46609 25.3056L11.5384 28.727L8.11709 30.7993C7.8809 30.9424 7.8054 31.2498 7.94847 31.486C8.09153 31.7222 8.39898 31.7977 8.63517 31.6546L12.4842 29.3233ZM13 1C12.6847 0.61194 12.6845 0.612118 12.6842 0.612377C12.6839 0.612574 12.6835 0.612916 12.683 0.613308C12.6821 0.614092 12.6807 0.615204 12.6789 0.616642C12.6754 0.619517 12.6703 0.623694 12.6637 0.629155C12.6503 0.640076 12.6308 0.656136 12.6055 0.677192C12.5547 0.719304 12.4803 0.781411 12.3848 0.862388C12.1938 1.02433 11.9181 1.26181 11.577 1.56584C10.895 2.17372 9.95058 3.04852 8.8985 4.11819C6.79924 6.25256 4.25042 9.18382 2.511 12.333C0.779912 15.4672 -0.208745 18.927 1.00849 22.0489C2.23147 25.1855 5.6009 27.7838 12.1059 29.3812L12.3444 28.41C5.96416 26.8433 2.98787 24.3727 1.94017 21.6856C0.886721 18.9838 1.70243 15.8653 3.38635 12.8165C5.06195 9.78285 7.53879 6.92672 9.61144 4.81941C10.6453 3.76826 11.5734 2.90865 12.2424 2.31234C12.5768 2.01427 12.8463 1.78221 13.0315 1.62513C13.1242 1.5466 13.1957 1.48682 13.2438 1.44693C13.2679 1.42699 13.286 1.41202 13.2981 1.40216C13.3041 1.39723 13.3085 1.39358 13.3114 1.39122C13.3129 1.39004 13.3139 1.38919 13.3146 1.38866C13.3149 1.3884 13.3151 1.38825 13.3152 1.38812C13.3153 1.38805 13.3153 1.38806 13 1Z"
              fill="black"
            />
          </svg>
        </div>

        {/* Idea */}
        <Link to="/product/ideas" className="w-2/3">
          <div className="bg-mph-light-gray p-8 rounded-xl w-full flex flex-row items-center cursor-pointer transform transition duration-500 hover:scale-105">
            <div className="container mx-auto">
              <div className="flex items-center lg:w-4/5 mx-auto border-b pb-10 mb-10 border-gray-600 sm:flex-row flex-col">
                <div className="flex-grow sm:text-left text-center mt-6 sm:mt-0 space-y-3">
                  <h2 className="text-black font-bold text-3xl title-font mb-2">
                    Create and Nurture Idea
                  </h2>
                  <p className="leading-relaxed text-base text-gray-600">
                    Create Idea and organize them based on theme.
                  </p>
                  <p className="leading-relaxed text-base text-gray-600">
                    Store all the information, links and mockups in one place.
                  </p>
                  <p className="leading-relaxed text-base text-gray-600">
                    Create Iterations and plan when and how to release it.
                  </p>
                  <p className="leading-relaxed text-base text-gray-600">
                    Prioritize what to work on using predefined templates and
                    feedback received from your customers.
                  </p>
                  <p className="leading-relaxed text-base text-gray-600">
                    Discuss the idea with your team right in the app.
                  </p>
                </div>
                <div className="sm:w-40 sm:h-40 h-20 w-20 sm:ml-10 inline-flex items-center justify-center text-mph-orange flex-shrink-0 order-first sm:order-none">
                  <FcIdea className="w-full h-full" />
                </div>
              </div>
            </div>
          </div>
        </Link>
        <div className="w-2/3 inline-flex justify-end -mt-12 -mb-14 z-30">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="200"
            height="200"
            viewBox="0 0 40 40"
            fill="none"
          >
            <g>
              <path
                d="M5.51583 29.3232C5.27963 29.1801 5.20413 28.8727 5.34723 28.6365L7.67863 24.7875C7.82163 24.5513 8.12912 24.4758 8.36532 24.6188C8.60151 24.7619 8.67701 25.0694 8.53394 25.3055L6.46163 28.7269L9.88294 30.7992C10.1191 30.9423 10.1946 31.2497 10.0516 31.4859C9.9085 31.7221 9.60105 31.7976 9.36486 31.6545L5.51583 29.3232ZM5.00003 0.999941C5.31533 0.611881 5.31553 0.612059 5.31583 0.612318C5.31613 0.612515 5.31653 0.612857 5.31703 0.613249C5.31793 0.614033 5.31933 0.615145 5.32113 0.616583C5.32463 0.619458 5.32973 0.623635 5.33633 0.629096C5.34973 0.640017 5.36923 0.656077 5.39453 0.677133C5.44533 0.719245 5.51973 0.781352 5.61523 0.862329C5.80623 1.02427 6.08193 1.26175 6.42303 1.56578C7.10503 2.17366 8.04945 3.04846 9.10153 4.11813C11.2008 6.2525 13.7496 9.18376 15.489 12.3329C17.2201 15.4671 18.2088 18.9269 16.9915 22.0488C15.7686 25.1854 12.3991 27.7837 5.89413 29.3811L5.65563 28.4099C12.0359 26.8432 15.0122 24.3726 16.0599 21.6855C17.1133 18.9837 16.2976 15.8652 14.6137 12.8164C12.9381 9.78279 10.4612 6.92666 8.38859 4.81935C7.35473 3.7682 6.42663 2.90859 5.75763 2.31228C5.42323 2.01421 5.15373 1.78215 4.96853 1.62507C4.87583 1.54654 4.80433 1.48676 4.75623 1.44687C4.73213 1.42693 4.71403 1.41196 4.70193 1.4021C4.69593 1.39717 4.69153 1.39352 4.68863 1.39116C4.68713 1.38998 4.68613 1.38913 4.68543 1.3886C4.68513 1.38834 4.68493 1.38819 4.68483 1.38806C4.68473 1.38799 4.68473 1.388 5.00003 0.999941Z"
                fill="black"
              />
            </g>
          </svg>
        </div>
        {/* Roadmap */}
        <Link to="/product/roadmaps" className="w-2/3">
          <div className="bg-mph-light-gray p-8 rounded-xl w-full flex flex-row items-center cursor-pointer transform transition duration-500 hover:scale-105">
            <div className="container mx-auto">
              <div className="flex items-center lg:w-4/5 mx-auto border-b pb-10 mb-10 border-gray-600 sm:flex-row flex-col">
                <div className="sm:w-40 sm:h-40 h-20 w-20 sm:mr-10 inline-flex items-center justify-center text-mph-orange flex-shrink-0">
                  <BiColumns className="w-full h-full" />
                </div>
                <div className="flex-grow sm:text-left text-center mt-6 sm:mt-0 space-y-3">
                  <h2 className="text-black font-bold text-3xl title-font mb-2">
                    Share Roadmaps to align everyone (Coming Soon)
                  </h2>
                  <p className="leading-relaxed text-base text-gray-600">
                    A public road map for your customers to vote and see what is
                    coming next.
                  </p>
                  <p className="leading-relaxed text-base text-gray-600">
                    Separate roadmap for you and your internal stakeholders for
                    a bigger picture.
                  </p>
                  <p className="leading-relaxed text-base text-gray-600">
                    Create Strategy and see the impact of your deliveries and theme
                    created by you.
                  </p>
                  <p className="leading-relaxed text-base text-gray-600">
                  Prioritize what to work on and align everyone in your
                    organization.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Link>
        <div className="w-2/3 inline-flex justify-start -mt-12 -mb-16 z-30">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="200"
            height="200"
            viewBox="0 0 40 40"
            fill="none"
          >
            <path
              d="M12.4842 29.3233C12.7204 29.1802 12.7959 28.8728 12.6528 28.6366L10.3214 24.7876C10.1784 24.5514 9.87091 24.4759 9.63471 24.6189C9.39852 24.762 9.32302 25.0695 9.46609 25.3056L11.5384 28.727L8.11709 30.7993C7.8809 30.9424 7.8054 31.2498 7.94847 31.486C8.09153 31.7222 8.39898 31.7977 8.63517 31.6546L12.4842 29.3233ZM13 1C12.6847 0.61194 12.6845 0.612118 12.6842 0.612377C12.6839 0.612574 12.6835 0.612916 12.683 0.613308C12.6821 0.614092 12.6807 0.615204 12.6789 0.616642C12.6754 0.619517 12.6703 0.623694 12.6637 0.629155C12.6503 0.640076 12.6308 0.656136 12.6055 0.677192C12.5547 0.719304 12.4803 0.781411 12.3848 0.862388C12.1938 1.02433 11.9181 1.26181 11.577 1.56584C10.895 2.17372 9.95058 3.04852 8.8985 4.11819C6.79924 6.25256 4.25042 9.18382 2.511 12.333C0.779912 15.4672 -0.208745 18.927 1.00849 22.0489C2.23147 25.1855 5.6009 27.7838 12.1059 29.3812L12.3444 28.41C5.96416 26.8433 2.98787 24.3727 1.94017 21.6856C0.886721 18.9838 1.70243 15.8653 3.38635 12.8165C5.06195 9.78285 7.53879 6.92672 9.61144 4.81941C10.6453 3.76826 11.5734 2.90865 12.2424 2.31234C12.5768 2.01427 12.8463 1.78221 13.0315 1.62513C13.1242 1.5466 13.1957 1.48682 13.2438 1.44693C13.2679 1.42699 13.286 1.41202 13.2981 1.40216C13.3041 1.39723 13.3085 1.39358 13.3114 1.39122C13.3129 1.39004 13.3139 1.38919 13.3146 1.38866C13.3149 1.3884 13.3151 1.38825 13.3152 1.38812C13.3153 1.38805 13.3153 1.38806 13 1Z"
              fill="black"
            />
          </svg>
        </div>

        {/* Testimonials */}
        <Link to="/product/testimonial" className="w-2/3">
          <div className="bg-mph-light-gray p-8 rounded-xl w-full flex flex-row items-center cursor-pointer transform transition duration-500 hover:scale-105">
            <div className="container mx-auto">
              <div className="flex items-center lg:w-4/5 mx-auto border-b pb-10 mb-10 border-gray-600 sm:flex-row flex-col">
                <div className="flex-grow sm:text-left text-center mt-6 sm:mt-0 space-y-3">
                  <h2 className="text-black font-bold text-3xl title-font mb-2">
                    Gather and Show the Love
                  </h2>
                  <p className="leading-relaxed text-base text-gray-600">
                    Collect testimonials via your customer success team or
                    integrate our API.
                  </p>
                  <p className="leading-relaxed text-base text-gray-600">
                    Convert positive feedback to testimonial.
                  </p>
                  <p className="leading-relaxed text-base text-gray-600">
                    Show all the love in public wall of Love. (Coming Soon)
                  </p>
                  <p className="leading-relaxed text-base text-gray-600">
                    Manage all the testimonials in one place.
                  </p>
                  <p className="leading-relaxed text-base text-gray-600">
                    Use our API to show them wherever you like.
                  </p>
                </div>
                <div className="sm:w-40 sm:h-40 h-20 w-20 sm:mr-10 inline-flex items-center justify-center text-red-600 flex-shrink-0 order-first sm:order-none">
                  <FaHeart className="w-full h-full" />
                </div>
              </div>
            </div>
          </div>
        </Link>
        <div className="w-2/3 hidden sm:inline-flex justify-end -mt-12 -mb-24 z-30">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="200"
            height="200"
            viewBox="0 0 40 40"
            fill="none"
          >
            <g>
              <path
                d="M5.51583 29.3232C5.27963 29.1801 5.20413 28.8727 5.34723 28.6365L7.67863 24.7875C7.82163 24.5513 8.12912 24.4758 8.36532 24.6188C8.60151 24.7619 8.67701 25.0694 8.53394 25.3055L6.46163 28.7269L9.88294 30.7992C10.1191 30.9423 10.1946 31.2497 10.0516 31.4859C9.9085 31.7221 9.60105 31.7976 9.36486 31.6545L5.51583 29.3232ZM5.00003 0.999941C5.31533 0.611881 5.31553 0.612059 5.31583 0.612318C5.31613 0.612515 5.31653 0.612857 5.31703 0.613249C5.31793 0.614033 5.31933 0.615145 5.32113 0.616583C5.32463 0.619458 5.32973 0.623635 5.33633 0.629096C5.34973 0.640017 5.36923 0.656077 5.39453 0.677133C5.44533 0.719245 5.51973 0.781352 5.61523 0.862329C5.80623 1.02427 6.08193 1.26175 6.42303 1.56578C7.10503 2.17366 8.04945 3.04846 9.10153 4.11813C11.2008 6.2525 13.7496 9.18376 15.489 12.3329C17.2201 15.4671 18.2088 18.9269 16.9915 22.0488C15.7686 25.1854 12.3991 27.7837 5.89413 29.3811L5.65563 28.4099C12.0359 26.8432 15.0122 24.3726 16.0599 21.6855C17.1133 18.9837 16.2976 15.8652 14.6137 12.8164C12.9381 9.78279 10.4612 6.92666 8.38859 4.81935C7.35473 3.7682 6.42663 2.90859 5.75763 2.31228C5.42323 2.01421 5.15373 1.78215 4.96853 1.62507C4.87583 1.54654 4.80433 1.48676 4.75623 1.44687C4.73213 1.42693 4.71403 1.41196 4.70193 1.4021C4.69593 1.39717 4.69153 1.39352 4.68863 1.39116C4.68713 1.38998 4.68613 1.38913 4.68543 1.3886C4.68513 1.38834 4.68493 1.38819 4.68483 1.38806C4.68473 1.38799 4.68473 1.388 5.00003 0.999941Z"
                fill="black"
              />
            </g>
          </svg>
        </div>
        <div className="flex flex-col text-center w-full mb-20 mt-10">
          <span className="font-bold text-2xl sm:text-5xl text-black tracking-wide leading-loose text-center py-2">
            Take control of your
            <br />
            product development
          </span>
        </div>
        <div className="w-full md:w-1/2 py-4 flex flex-col justify-center items-center">
          <Link
            to="https://app.myproducthub.com?action=Register"
            target="_blank"
            className="p-2 text-lg sm:p-6 rounded-lg bg-mph-ocean-blue text-white sm:text-2xl cursor-pointer transform transition duration-500 hover:scale-110"
          >
            Get Started For Free
          </Link>

          <p className="text-sm text-gray-500 mt-4">
            *14 Day Free Trial. No Card Required.
          </p>
        </div>
      </div>
    </section>
  );
}

export default Works;
